@import 'style-guide.scss';
@import './../global/utilities';
$lightGrey: #999;
$borderGrey: #ccc;
$black: #000;
$calloutBlack: #333;

.ourTopCategory {
	.hpTitle {
		font-size: 3rem;
		font-weight: 400;
		margin: 15px 0;

		h1 {
			display: inline;
			font-size: 3rem;
			font-weight: 400;
			margin: 15px 0;
		}
	}

	.hpTitleReDesign {
		display: block;
		font-size: 4rem;
		font-weight: 400;
		letter-spacing: -0.04em;
		margin: 15px 0;
		text-align: center;
	}

	&.homeOurTopCategory {
		.hpTitleReDesign {
			&.furnitureTitle {
				margin-top: 90px;
			}
		}

		%bucketStyles {
			&.bucketText5Across {
				.bucketText5Across-boxLink {
					align-items: center;
					border: unset;
					display: block;

					&:hover {

						span,
						strong {
							color: $lpRed;
							text-decoration: underline;
						}
					}

					img {
						max-width: 150px;
						text-align: center;
						width: auto;
					}

					span {
						color: $black;
						display: block;
						font-weight: 500;
						margin-left: auto;
						text-align: center;
						width: auto;

						@media (min-width: 1280px) {
							font-size: 1.65rem;
						}
					}
				}
			}
		}
	}

	&.furnitureDecorCatHome {
		.bucketText5Across.hpBucketWrapper {
			grid-template-columns: repeat(7, 1fr);
			grid-row-gap: 40px;
			width: 100%;

			.bucketText5Across-boxLink {
				img {
					max-width: 120px;
					object-fit: cover;
					height: 120px;
				}
			}
		}
	}
}

%bucketStyles {
	margin: 35px 0 0;
	width: 100%;

	.buttonRightArrow {
		line-height: inherit;
	}

	&.marginTop0 {
		margin-top: 0;
	}

	h2,
	.h2 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
	}

	h2.subtitle {
		font-weight: normal;
	}

	a {
		display: block;
		line-height: 2.5em;
		text-decoration: none;
		text-transform: uppercase;

		span {
			line-height: 2.4em;
		}
	}

	.hpBucketWrapper--lightingDesign {
		a {
			margin-right: 2px;
		}
	}

	.bannerLink {
		display: block;
		line-height: 2.5em;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;

		.subLinks {
			display: flex;
			flex-wrap: wrap;
			margin-top: 39px;

			a {
				flex: 39%;
				margin: 10px 0;

				h2 {
					white-space: nowrap;
				}
			}

			.firstItem {
				flex: 29%;
			}

			.lastItem {
				flex: 53%;
			}

			&.seatingSubLinks {
				a {
					&:nth-child(even) {
						h2 {
							padding-left: 65px;
							text-align: left;
						}
					}
				}
			}
		}

		.titleHeading {
			color: #000;
			font-size: min(max(1.6vw, 1.8rem), 2.3rem);
			font-weight: 500;

			&.furnitureSaleTitleBed {
				padding-right: 22px;
			}

			@media screen and(max-width: 1096px) {
				font-size: min(max(1.6vw, 1.7rem), 2.3rem);
			}

			.hpTitleHeading {
				line-height: unset;
			}

			&:hover {
				.hpTitleHeading {
					color: var(--red);
					text-decoration: underline;
				}
			}
		}

		.headingMargin {
			margin-top: 46px;
		}

		.subLinkMargin {
			margin: 65px 45px;
		}

		.subLinkText {
			text-align: right;
		}


		.alignItemsLeft {
			margin-left: 34px;
			text-align: left;
		}
	}

	&.bucketBanner {
		&--withOverlayLogo {
			position: relative;

			.bucketBanner__overlayLogo {
				left: 60px;
				position: absolute;
				top: 60px;
			}

		}

		&--withOverlayBottomText {
			position: relative;

			.bucketBanner__overlayText {
				bottom: 10px;
				color: #fff;
				font-size: 1.7rem;
				line-height: 1;
				position: absolute;
				right: 20px;
			}
		}

		&.hpBucketHoverHomePage {
			.bannerLink {
				.alignLeft {
					& + .hpBucketHoverImage {
						margin-left: auto;
					}

				}

				.hpBucketHoverImage {
					overflow: hidden;
					position: relative;
					width: 57%;

					img {
						transition: 400ms ease-in-out;
						width: 100%;
					}

					@media (hover: hover) {
						&:hover {
							img {
								transform: scale(1.05);
							}
						}
					}
				}

				.titleHeading {
					.buttonRightArrow {
						display: inline-block;
						transition: 300ms ease-in-out;
					}

					@media (hover: hover) {
						&:hover {
							.buttonRightArrow {
								transform: translateX(10px);
							}
						}
					}
				}
			}
		}

		a {
			color: $lpBlue;
			position: relative;

			&:hover {
				color: $black;
			}
		}

		.cursorDefault {
			position: relative;
		}

		.bannerImageReDesign {
			&--shortContainer {
				flex-basis: 43%;
			}

			&--short {
				aspect-ratio: 550/342;
				max-width: 550px;
			}

			&--pros {
				padding-top: 2%;
				width: 29%;
			}
		}

		img {
			display: block;
			height: auto;
			width: 100%;
		}

		strong {
			display: block;
			float: right;
			width: 50%;
		}

		.copyWrapper {
			box-sizing: border-box;
			color: #000;
			font-size: 40px;
			height: 100%;
			margin: 0 1.8% 0 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: 45%;

			&--inspireMe {
				margin: 0;
				width: 43%;

				.copyContainer {
					.inspireMeLogo {
						margin: 0 0 0 45px;
						width: 80%;
					}
				}
			}

			&--small {
				width: 35%;
			}

			&--wide {
				width: 55%;
			}

			&--xWide {
				width: 75%;
			}

			&--full {
				width: 100%;
			}

			&--ReDesign {
				&--wide {
					background-color: #f1f1f1;
					margin: 0;
					position: unset;
					width: 57%;
				}

				&--left {
					background-color: #f1f1f1;
					margin: 0;
					width: 43%;

					.saleCopyReDesignFS {
						& + .subLinks.hpBucketWrapperML {
							a {
								&:nth-child(odd) {
									flex-basis: 30%;
								}

								&:nth-child(even) {
									flex-basis: 60%;
								}
							}
						}

						&.newSaleRedesign {
							& + .subLinks.hpBucketWrapperML.subLinksContainer {
								margin-left: auto;

								&.fountainSubLinks {
									margin-left: 85px;
								}

								a {

									&:nth-child(odd) {
										flex: 29%;
									}

									&:nth-child(even) {
										flex: 56%;
									}


								}
							}

						}
					}

				}

				&--right {
					background-color: #f1f1f1;
					margin: 0;
					width: 45%;
				}

				&--pros {
					margin: 0;
					width: 39%;
				}
			}

			&.alignLeft {
				left: 0;
				margin: 0;
				right: auto;
			}

			&.alignMiddle {
				left: 0;
				margin: 0 auto;
				right: 0;
			}

			.marginTop10 {
				margin-top: 10px;
			}

			.marginTop20 {
				margin-top: 20px;
			}

			.marginTop30 {
				margin-top: 30px;
			}

			.lightingcopyContainer {
				margin: 0;
				position: relative;
				width: 100%;

				.alignRight {
					margin-right: 45px;
					text-align: right;
				}

				.alignLeft {
					margin-left: 55px;
					text-align: left;
				}

				.lineHeight {
					line-height: 1.2;
				}

				.titleReDesign {
					font-size: min(max(4.2vw, 4.2rem), 6.5rem);
					font-weight: 300;
					letter-spacing: -0.06em;
					line-height: min(max(4.2vw, 4.2rem), 6.5rem);
					margin-top: 40px;
					text-transform: none;

					&--lightingFont {
						font-size: min(max(4.2vw, 4.8rem), 6.5rem);
					}

					&.janniferBucketBannerTitle {
						max-width: 550px;
					}
				}

				.saleCopyReDesign {
					font-size: min(max(1.7vw, 1.8rem), 2.3rem);
					font-weight: 400;
					margin-top: 15px;
					text-transform: none;

					&--marginTop0 {
						margin-top: 0;
					}

					&--marginTop5 {
						margin-top: 5px;
					}

					&--paddingLeft5 {
						padding-left: 5px;
					}
				}

				.ctaReDesign {
					font-size: min(max(1.7vw, 1.8rem), 2.3rem);
					font-weight: 500;
					margin-top: 15px;

					.hpTitleHeading {
						line-height: unset;
					}

					.buttonRightArrow {
						display: inline-block;
						transition: 300ms ease-in-out;
					}

					&:hover {
						.hpTitleHeading {
							color: var(--red);
							text-decoration: underline;
						}

						.buttonRightArrow {
							transform: translateX(10px);
						}
					}
				}
			}

			.copyContainer {
				left: 50%;
				margin: 0;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 100%;

				&--topWrapper {
					top: 48%;
				}

				&--topWrappererNew {
					top: 32%;
				}

				&--bottomWrapper {
					bottom: 15%;
					top: auto;
				}

				&.copyContainer--furnitureSale {
					.saleCopyReDesign {
						max-width: 480px;
						margin-left: auto;

						&.hpBucketWrapperML {
							margin-left: auto;
						}
					}
				}

				&.fountainsale--container {
					.subLinks {
						max-width: 420px;
					}

					.headingMargin {
						margin-top: 70px;
					}
				}

				.alignRight {
					margin-right: 45px;
					text-align: right;
				}

				.alignLeft {
					margin-left: 55px;
					text-align: left;
				}

				.titleReDesign {
					font-size: min(max(4.8vw, 4.8rem), 6.5rem);
					font-weight: 300;
					letter-spacing: -0.06em;
					line-height: 1em;
					text-transform: none;

					&--catalog {
						margin-bottom: 0.25em;
						margin-top: 0.75em;
					}

					&--lightingFont {
						font-size: min(max(4.2vw, 4.8rem), 6.5rem);
					}
				}

				.luxuryLightLogoAlignment {
					margin: 0 0 0 91px;
					width: 67%;
				}

				.saleCopyReDesign {
					font-size: min(max(1.7vw, 1.8rem), 2.3rem);
					font-weight: 400;
					letter-spacing: -0.03em;
					margin-top: 35px;
					text-transform: none;

					&.saleCopyReDesignNS {
						max-width: 410px;

						@media only screen and (min-width: 1400px) {
							max-width: 420px;
						}
					}

					&.saleCopyPara {
						margin-left: auto;
						max-width: 350px;
					}

					&.saleCeilingTitle {
						& + .subLinks.subLinkMargin {
							margin-left: 15px;

							a {
								&:nth-child(odd) {
									margin-right: 30px;
									text-align: right;
								}

								h2 {
									margin-right: 0;
								}
							}
						}
					}

					&--marginTop0 {
						margin-top: 0;
					}

					&--marginTop5 {
						margin-top: 5px;
					}

					&--paddingLeft5 {
						padding-left: 5px;
					}

					&--paddingTop10 {
						padding-top: 10px;
					}
				}

				.fanDesign {
					margin-left: auto;
					margin-right: 45px;
					max-width: 460px;
					text-align: right;
				}

				.ctaReDesign {
					font-size: min(max(1.7vw, 1.8rem), 2.3rem);
					font-weight: 500;
					margin-top: 70px;

					&--mt20 {
						margin-top: 20px;
					}

					&--mt0 {
						margin-top: 0;

						.buttonRightArrow {
							transition: 400ms ease-in-out;
						}

						&:hover {
							.hpBucketWrapperTitle {
								color: var(--red);
								text-decoration: underline;
							}

							.buttonRightArrow {
								transform: translateX(10px);
							}
						}
					}
				}

				&.luxuryLightingContainer {
					color: #fff;
					transform: translate(-60%, -50%);
				}

				.stiffelLightingContainer {
					margin-left: auto;
					margin-right: auto;
					max-width: 30%;
				}

				.colorplusContainer {
					margin-left: auto;
					margin-right: auto;
					max-width: 30%;
				}

				.stJudeContainer {
					margin-left: auto;
					margin-right: auto;
					max-width: 40%;
				}

				.stiffelContestContainer {
					margin-bottom: 15px;
					margin-left: auto;
					margin-right: auto;
					margin-top: 20px;
					max-width: 30%;
				}

				span {
					display: inline-block;
				}

				.subtitle {
					font-size: 2.2vw;
					line-height: 2.75vw;
					margin-left: auto;
					margin-right: auto;
					text-transform: none;
					width: 75%;

					.stiffelsalesubtitle {
						margin-top: 15px;
					}

					&.small {
						font-size: 1.65vw;
						line-height: 2vw;
					}

					&.xSmall {
						font-size: 1.6vw;
						line-height: 2vw;
					}

					&.xxSmall {
						font-size: 1.4vw;
						line-height: 2vw;
					}

					&.smallDunEdwards {
						font-size: 1.65vw;
						line-height: 2vw;
						margin-top: 10px;
					}

					&.medium {
						font-size: 1.8vw;
						line-height: 2vw;
					}
				}

				@media screen and (min-width: 1400px) {
					.subtitle {
						font-size: 32px;
						line-height: 40px;

						&.small {
							font-size: 24px;
							line-height: 30px;
						}

						&.xSmall {
							font-size: 22px;
							line-height: 27px;
						}

						&.xxSmall {
							font-size: 20px;
							line-height: 27px;
						}

						&.smallDunEdwards {
							font-size: 24px;
							line-height: 30px;
							margin-top: 10px;
						}
					}
				}

				@media screen and (max-width: 960px) {
					.subtitle {
						font-size: 21px;
						line-height: 26px;

						&.small {
							font-size: 16px;
							line-height: 19px;
						}

						&.xSmall {
							font-size: 15px;
							line-height: 19px;
						}

						&.smallDunEdwards {
							font-size: 16px;
							line-height: 19px;
							margin-top: 10px;
						}
					}
				}

				.catalogSubtitle {
					font-size: 2.2vw;
					line-height: 2.75vw;
					margin-left: auto;
					margin-right: auto;
					text-transform: none;
					width: 85%;

					&.xSmall {
						font-size: 1.6vw;
						line-height: 2vw;
					}

					@media screen and (min-width: 1400px) {
						font-size: 32px;
						line-height: 40px;

						&.xSmall {
							font-size: 22px;
							line-height: 27px;
						}
					}

					@media screen and (max-width: 960px) {
						font-size: 21px;
						line-height: 26px;

						&.xSmall {
							font-size: 15px;
							line-height: 19px;
						}
					}
				}

				.title {
					font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
					font-size: 4.13vw;
					font-weight: normal;
					line-height: 4.81vw;
					margin: 0 auto;
					width: 90%;
					word-spacing: 0.1em;

					&.small {
						font-size: 3.85vw;
						line-height: 4.12vw;
					}

					&.xSmall {
						font-size: 3.4vw;
						line-height: 3.8vw;
					}
				}

				@media screen and (min-width: 1400px) {
					.title {
						font-size: 60px;
						line-height: 70px;

						&.small {
							font-size: 56px;
							line-height: 60px;
						}

						&.xSmall {
							font-size: 46px;
							line-height: 52px;
						}
					}
				}

				@media screen and (max-width: 960px) {
					.title {
						font-size: 40px;
						line-height: 50px;

						&.small {
							font-size: 36px;
							line-height: 42px;
						}

						&.xSmall {
							font-size: 32px;
							line-height: 36px;
						}
					}
				}

				.saleCopy {
					font-size: 1.45vw;
					line-height: 1.75vw;
					margin: 2.0625vw auto 0;

					&.small {
						font-size: 1.2vw;
					}

					&.capitalize {
						text-transform: capitalize;
					}

					&.ttnone {
						text-transform: none;
					}

					&.stiffelsaleCopyHead {
						margin-top: 10px !important;
					}

					.stiffelSaleCopySubHead {
						margin-top: 10px;
					}
				}

				.saleCopyDunEdwards {
					align-items: center;
					display: flex;
					font-size: 1.45vw;
					justify-content: center;
					line-height: 1.75vw;
					margin: 2.0625vw auto 0;

					> div:last-child {
						margin-left: 20px;
					}
				}

				@media screen and (min-width: 1400px) {
					.saleCopy {
						font-size: 20px;
						line-height: 24px;
						margin: 30px auto 0;
					}

					.saleCopyDunEdwards {
						align-items: center;
						display: flex;
						font-size: 20px;
						justify-content: center;
						line-height: 24px;
						margin: 30px auto 0;

						> div:last-child {
							margin-left: 20px;
						}
					}
				}

				@media screen and (max-width: 960px) {
					.saleCopy {
						font-size: 14px;
						line-height: 17px;
						margin: 20px auto 0;
					}

					.saleCopyDunEdwards {
						align-items: center;
						display: flex;
						font-size: 14px;
						justify-content: center;
						line-height: 17px;
						margin: 20px auto 0;

						> div:last-child {
							margin-left: 20px;
						}
					}
				}

				a.buttonLink,
				button {
					background: none;
					color: #000;
					font-size: 1.51vw;
					font-weight: 500;
					line-height: 26px;
					margin-top: 30px;
					min-width: 23vw;

					&.luxuryLightingBtn {
						box-shadow: inset 0 0 0 1px #999;
						color: #fff;
					}

					&.lightningBtnWithoutBorder {
						border: none;
						text-transform: uppercase;

						.buttonRightArrow {
							transition: 400ms ease-in-out;
						}

						&:hover {
							.hpTitleHeading {
								color: var(--red);
								text-decoration: underline;
							}

							.buttonRightArrow {
								transform: translateX(10px);
							}
						}
					}
				}

				a.buttonLink.lightningBtnWithoutBorder {
					display: inline-block;
					&:first-of-type {
						margin-right: 6px;
					}
				}

				@media screen and (min-width: 1200px) {
					.buttonDunEdwards {
						font-size: 20px;
						min-width: 240px;
					}
				}

				@media screen and (min-width: 1400px) {
					.buttonDunEdwards {
						font-size: 20px;
						min-width: 240px;
					}
				}

				@media screen and (max-width: 960px) {
					.buttonDunEdwards {
						font-size: 14px;
						min-width: 220px;
					}
				}

				@media screen and (min-width: 1400px) {
					a.buttonLink,
					button {
						font-size: 22px;
						min-width: 340px;
					}
				}

				@media screen and (max-width: 960px) {
					a.buttonLink,
					button {
						font-size: 14px;
						min-width: 220px;
					}
				}

				.btnSaleBanner {
					border-width: 1px;
					margin-top: 10px;
					padding: 10px 0;
					width: 400px;
				}
			}

			.baseSaleDecor {
				button {
					margin-top: 10px;
					min-width: 29vw;
				}

				@media screen and (min-width: 1400px) {
					button {
						min-width: 33vw;
					}
				}

				@media screen and (max-width: 960px) {
					button {
						min-width: 220px;
					}
				}
			}
		}

		a.cursorDefault {
			cursor: default !important;
		}
	}

	&.bucketTextAcross--2 {
		--bucketTextAcross-columns-count: 2;
		--bucketTextAcross-gap: 30px 49px;
		--bucketTextAcross-img-width: 715px;

		@media (max-width: 1366px) {
			--bucketTextAcross-gap: 25px 35px;
		}

		@media (max-width: 1024px) {
			--bucketTextAcross-gap: 18px 25px;
		}
	}

	&.bucketTextAcross--4 {
		--bucketTextAcross-columns-count: 4;
		--bucketTextAcross-gap: 30px 49px;
		--bucketTextAcross-img-width: 323px;

		@media (max-width: 1366px) {
			--bucketTextAcross-gap: 25px 35px;
		}

		@media (max-width: 1024px) {
			--bucketTextAcross-gap: 18px 25px;
		}
	}

	&.bucketTextAcross--5 {
		--bucketTextAcross-columns-count: 5;
		--bucketTextAcross-gap: 30px 35px;
		--bucketTextAcross-img-width: 240px;

		@media (max-width: 1366px) {
			--bucketTextAcross-gap: 25px;
		}

		@media (max-width: 1024px) {
			--bucketTextAcross-gap: 18px;
		}
	}

	&.bucketTextAcross {
		display: grid;
		gap: var(--bucketTextAcross-gap);
		grid-template-columns: repeat(var(--bucketTextAcross-columns-count), 1fr);
		justify-items: center;
		margin: 0;
		max-width: 1440px;
		width: 100%;

		&--center-y {
			align-items: center;
		}

		&--padding {
			box-sizing: border-box;
			padding: 0 20px;
		}

		.bucketTextAcross__boxPadding {
			box-sizing: border-box;
			padding: 0 25px;
		}

		.bucketTextAcross__boxLink {
			display: flex;
			flex-direction: column;
			text-transform: none;

			&:hover span {
				text-decoration: underline;
			}

			img {
				display: block;
				width: var(--bucketTextAcross-img-width);
			}

			span {
				font-size: 2rem;
				line-height: 2.75rem;
				margin-top: 5px;

				@media (max-width: 1024px) {
					font-size: 1.65rem;
				}
			}
		}
	}

	&.bucketText5Across {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		margin: 10px 0 0;
		width: 100%;

		.bucketText5Across-boxLink {
			align-items: center;
			border: 1px solid #99999952;
			display: flex;
			line-height: 2.5em;
			margin-left: 10px;
			padding: 5px;
			text-align: center;
			text-decoration: none;
			text-transform: none;
			width: 100%;

			&:hover strong {
				color: $lpRed;
			}

			&:hover p {
				color: $black;
			}

			img {
				text-align: left;
				width: 60px;
			}

			span {
				font-size: 1.28rem;
				line-height: 1.75rem;
				margin-left: 10px;
				text-align: left;
				text-shadow: 0 0 1px #00000047;
				width: 60%;
			}

			@media (min-width: 1280px) {
				span {
					font-size: 1.65rem;
				}
			}
		}

		.bucketText5Across-boxLink:first-child {
			margin-left: 0;
		}
	}

	&.bucketText5Across {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		margin: 30px auto 0;
		width: 90%;

		&.confirmationExpired {
			width: 83%;
		}

		.bucketText5Across-boxLink {
			align-items: center;
			border: 1px solid #99999952;
			display: flex;
			line-height: 2.5em;
			margin-left: 10px;
			padding: 5px;
			text-align: center;
			text-decoration: none;
			text-transform: none;
			width: 100%;

			&:hover strong {
				color: $lpRed;
			}

			&:hover p {
				color: $black;
			}

			img {
				text-align: left;
				width: 60px;
			}

			span {
				font-size: 1.28rem;
				line-height: 1.75rem;
				margin-left: 10px;
				text-align: left;
				text-shadow: 0 0 1px #00000047;
				width: 60%;
			}

			@media (min-width: 1280px) {
				span {
					font-size: 1.65rem;
				}
			}
		}

		.bucketText5Across-boxLink:first-child {
			margin-left: 0;
		}
	}

	&.bucket2Across,
	&.bucket3Across,
	&.bucket4Across,
	&.bucket5Across,
	&.bucketText3Across {
		> div {
			float: left;
			text-align: center;

			strong {
				display: block;
			}

			&:first-child {
				margin-left: 0;
			}
		}

		&.hpBucketColorPlusWrapper > div {
			flex: 0 0 48%;
			max-width: 48%;

			.hpBucketOverlayLogo {
				padding-bottom: 0;
				padding-left: 0;
			}
		}

	}



	&.bucket2Across {
		display: flex;
		justify-content: space-between;
		width: 100%;

		> div {
			img {
				height: auto;
				max-width: 640px;
				min-width: 446px;
				width: 100%;

				&.imageNoBottomSpace {
					margin-bottom: 0;
				}
			}

			&.hpBucketWrapperHomeDecore {
				margin-right: 30px;
			}
		}
	}

	&.bucket3Across {
		&.trendSetting div {
			border: 1px solid $borderGrey;
			width: 290px;
		}
	}

	&.bucket2Across--withDescription.bucket2Across {
		.lightingBucketContainer {
			display: flex;
			justify-content: space-between;
			margin-right: 10px;

			.stiffel__logo {
				aspect-ratio: unset;
				display: inline-block;
				height: auto;
				margin-top: 16px;
				min-width: unset;
			}

			.luxuryLighting__logo {
				aspect-ratio: unset;
				display: inline-block;
				height: auto;
				margin-top: 16px;
				max-width: 200px;
				min-width: unset;
			}

			.bucketDescription__headingReDesign {
				padding-right: 15px;
			}
		}

		> div {
			img {
				max-width: 100%;
			}
		}
	}

	&.bucket2Across--withDescription,
	&.bucket3Across--withDescription,
	&.bucket4Across--withDescription {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		width: 100%;

		.circle {
			border-radius: 50%;
			height: 128px;
			margin: auto;
			overflow: hidden;
			position: relative;
			width: 128px;

			img {
				animation: none;
				display: inline;
				height: 100%;
				margin: 0 auto;
				min-width: 0;
				width: 100%;
			}
		}

		&.hpBucketWrapperImg {
			background-color: white;
			margin: 0 0 15px auto;
			max-width: 550px;
			padding-bottom: 10px;

			> div {
				img {
					height: auto;
				}
			}
		}

		> div,
		li {
			margin-right: 12px;
			position: relative;

			&:last-child {
				margin-right: 0;
			}

			div.hpBucketOverlayLogo {
				background-color: white;
				box-sizing: border-box;
				position: relative;
				padding-left: 26px;
				padding-bottom: 10px;

				.overlayStjude {
					aspect-ratio: unset;
					min-width: unset;
					max-width: 100px;
					width: unset;
					top: 0;
					left: 7%;
					position: absolute;
					height: auto;
				}

				.overlayColorPlusLogo {
					aspect-ratio: unset;
					height: auto;
					left: 15px;
					min-width: unset;
					position: absolute;
					top: 15px;
					width: unset;
				}


				.overlay-logo {
					align-items: center;
					bottom: 10px;
					display: flex;
					justify-content: space-between;
					margin: 0 3px;
					position: absolute;
					width: 79%;

					img {
						aspect-ratio: unset;
						max-width: 200px;
						min-width: unset;
						margin-left: 20px;
						width: unset;
					}
				}
			}

			img {
				height: 100%;
				margin: 0;
				max-width: 410px;
				min-width: 292px;
				width: 100%;

				&.inspireMeLogo {
					aspect-ratio: unset;
					height: unset;
					margin-top: 10px;
					max-width: 152px;
					min-width: unset;
				}
			}
		}

		.bucketDescription {
			background-color: rgba(255, 255, 255, 0.8);
			border: 1px solid #ccc;
			bottom: -45px;
			color: $black;
			font-family: var(--fontHeading);
			height: 68px;
			left: 50%;
			line-height: 1;
			margin-left: -136px;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			width: 272px;

			@media (min-width: 1280px) {
				font-size: 1.6rem;
				margin-left: -175px;
				width: 350px;
			}

			&--categoryOnly {
				bottom: -23px;
				height: 46px;
			}

			&--categoryOnlyCentered {
				bottom: -13px;
				height: 46px;
			}

			&__heading {
				color: $black;
				font-size: 0.92em;
				font-weight: 500;
				letter-spacing: 0.05em;
				margin: 16px 0 0;
				padding: 0;
				width: 100%;
			}

			&__headingReDesign {
				color: $black;
				font-family: var(--fontHeading);
				font-size: min(max(1.65vw, 1.8rem), 2.2rem);
				font-weight: 500;
				margin: 16px 0 0;
				padding: 0;
				width: 100%;

				.hpBucketWrapperTitle {
					line-height: unset;
				}

				&:hover {
					.hpBucketWrapperTitle {
						color: var(--red);
						line-height: unset;
						text-decoration: underline;
					}
				}
			}

			.capitalize {
				text-transform: capitalize;
			}

			.ttnone {
				text-transform: none;
			}

			&__message {
				font-size: 0.91em;
				font-weight: 100;
				letter-spacing: 0.07em;
				margin: 10px 0 0;
				padding: 0;
				width: 100%;

				&.noUpperCase {
					letter-spacing: 0;
					line-height: 1.2em;
					margin-top: 5px;
					padding-bottom: 15px;
					text-transform: none;
				}

				&__dash {
					font-weight: 200;
					letter-spacing: -1.5px;
					line-height: 1;
					padding: 0 0.8px;
				}
			}

			&__messageReDesign {
				font-size: min(max(1.4vw, 1.5rem), 2rem);
				font-weight: 400;
				margin: -10px 0 0;
				padding: 0;
				text-align: left;
				text-transform: none;
				width: 100%;

				&.noUpperCase {
					letter-spacing: 0;
					line-height: 1.2em;
					margin-top: 5px;
					padding-bottom: 15px;
					text-transform: none;
				}

				&__dash {
					font-weight: 200;
					letter-spacing: -1.5px;
					line-height: 1;
					padding: 0 0.8px;
				}
			}

			&__subHeadingReDesign {
				font-family: var(--fontHeading);
				font-size: min(max(1.35vw, 1.6rem), 1.8rem);
				font-weight: 400;
				line-height: 1.2em;
				margin-top: 10px;
				text-transform: none;
				width: 100%;
			}
		}

		#colorPlusWrapper {
			display: flex;
			justify-content: space-between;
			margin: 0 50px;
		}

		#colorPlusWrapper .logo {
			margin-top: -70px;
			min-width: 33%;
			width: 33%;
			width: 50px;
		}

		#colorPlusWrapper .stJude {
			margin-top: -80px;
		}

		&.hpBucketHomePage {

			div,
			li {
				a {

					div:first-child,
					li:first-child {
						overflow: hidden;

						img {
							transition: 400ms ease-in-out;
						}

						@media (hover: hover) {
							&:hover {
								img {
									transform: scale(1.05);

								}

								.overlay-logo {
									img {
										transform: unset;
									}
								}

								.overlayStjude {
									transform: unset;
								}

								.overlayColorPlusLogo {
									transform: unset;
								}
							}
						}
					}

					.bucketDescription__headingReDesign {
						.buttonRightArrow {
							display: inline-block;
							transition: 300ms ease-in-out;
						}

						@media (hover: hover) {
							&:hover {
								.buttonRightArrow {
									transform: translateX(10px);
								}
							}
						}
					}
				}
			}
		}

		&.semiAnnual_bucketDescription {
			.bucketDescription__messageReDesign.noUpperCase {
				margin-top: -4px;
			}
		}
	}

	&.bucket2Across--withBottomText {
		display: inline-block;

		> div img {
			margin: 0;
		}

		.bucketDescription {
			color: $black;
			font-family: var(--fontHeading);
			line-height: 1;
			text-align: center;

			&__heading {
				font-size: 1.4em;
				font-weight: 300;
				letter-spacing: 0.03em;
				margin-top: 20px;
			}

			&__message {
				font-size: 1.1em;
				font-weight: 300;
				letter-spacing: 0.03em;
				line-height: 1.4em;
				margin-top: 9px;
				padding: 0 50px;
				text-transform: none;

				&--noPadding {
					padding: 0;
				}
			}

			.calloutBtn {
				border-color: $calloutBlack;
				color: $calloutBlack;
				font-size: 0.94em;
				letter-spacing: 0.09em;
				line-height: 1.4em;
				margin-top: 14px;
				padding: 6px 12px;
				position: relative;

				&:hover {
					color: $calloutBlack;
				}
			}
		}
	}

	&.bucket2Across--withDescription .bucketDescription--alt1 {
		height: 80px;

		@media (min-width: 1280px) {
			width: 350px;
		}
	}

	&.bucket3Across--withDescription .bucketDescription--alt1 {
		height: 80px;
	}

	&.bucket4Across {
		.container {
			width: 25%;

			.copyContainer {
				margin: 0 auto;
				width: 100%;
			}
		}

		div {
			margin-left: 24px;
			width: 210px;

			img {
				margin-bottom: 10px;
			}
		}
	}

	&.bucket5Across {
		div {
			margin-left: 20px;
			width: 166px;
		}
	}

	&.bucketText3Across {
		display: flex;
		justify-content: center;
		width: 100%;

		.bucketText3Across-boxLink {
			border: 1px solid $lightGrey;
			line-height: 2.5em;
			margin-left: 18px;
			padding: 25px 0;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			width: 100%;

			.buttonRightArrow {
				display: inline-block;
				transition: 400ms ease-in-out;
			}

			&:hover {
				strong {
					color: $lpRed;
				}

				p {
					color: $black;
				}

				.hpTitleHeading {
					color: var(--red);
					text-decoration: underline;
				}

				.buttonRightArrow {
					transform: translateX(10px);
				}
			}

			strong,
			p {
				font-size: 1.8rem;
				line-height: 1.3em;
				padding-top: 5px;
			}

			strong {
				color: $black;
				font-family: var(--fontHeading);
				font-size: 1.2em;

				.currencySymbol {
					line-height: 1.4em;
				}
			}

			h2 {
				font-size: 1.2em;
				letter-spacing: 0;
				line-height: 1.3em;
				padding-bottom: 8px;
				padding-top: 5px;
			}

			p {
				margin: 0;
				text-transform: none;
			}
		}

		.bucketText3Across-openBox {
			align-items: center;
			border: 3px solid #fff;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			line-height: 2.5em;
			margin-left: 12px;
			outline-offset: 2px;
			padding: 32px 0 22px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			width: 100%;

			img {
				display: flex;
				margin-bottom: 6px;
				width: 61%;
			}

			.shopNowMargin {
				margin-top: 12px;
			}

			strong {
				font-family: var(--fontHeading);
				font-size: 1.3em;
			}

			p {
				font-size: 3em;
				margin: 0;
			}

			&.openBoxContainer {
				background: #000;

				p {
					font-family: inherit;
					font-size: 45px;
					font-weight: 500;
					text-transform: none;
				}
			}

			&.dailySalesContainer {
				background: #555;
				margin-left: 0;
			}

			&.clearanceContainer {
				background: #c00;
			}
		}

		.bucketText3Across-openBox-descriptive {
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: 2rem;
			justify-content: center;
			line-height: 2.4rem;
			margin-top: -20px;
			text-align: center;
			width: 100%;
		}
	}

	&.bucketSplash {
		margin-top: 22px;
	}

	&.bucket1by2,
	&.bucket2by1 {
		display: flex;
		display: flex;

		> div {
			flex: 1;
			flex: 1;
		}

		div,
		a {
			align-items: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			flex-direction: column;
			position: relative;
			width: 442px;

			.actionButton {
				bottom: 10px;
				left: 0;
				position: absolute;
				text-align: center;
				width: 100%;
			}

			.calloutBtn {
				background: transparent;
				box-shadow: inset 0 0 0 1px $black;
				color: $black;
				font-weight: 500;
				letter-spacing: 1px;
				line-height: 2em;

				&:hover {
					color: $highlightRed;
				}
			}
		}
	}

	&.bucket1by2 {
		> div:first-child {
			margin-right: 14px;
		}

		> div:last-child {
			display: flex;
			display: flex;
			flex-direction: column;
			flex-direction: column;
			justify-content: space-between;
			justify-content: space-between;
			margin-left: 14px;
		}
	}

	&.bucket2by1 {
		> div:last-child {
			margin-left: 14px;
		}

		> div:first-child {
			display: flex;
			display: flex;
			flex-direction: column;
			flex-direction: column;
			justify-content: space-between;
			justify-content: space-between;
			margin-right: 14px;
		}
	}
}

.hpBucketWrapper {
	&:has(.hpBucketWrapper__bannerImg) .hpBucketWrapper__overlay-btn {
		box-shadow: var(--home-banner-drop-shadow);
	}

	&--withOverlay {
		position: relative;

		a img {
			display: block;
		}
	}

	&--fullWidth {
		width: 100%;
	}

	&__overlay {
		align-items: center;
		display: flex;
		flex-direction: column;
		left: 60px;
		min-width: 200px;
		max-width: 25%;
		pointer-events: none; // let click go through underneath the banner itself
		position: absolute;
		top: 60px;
		z-index: 1;

		&--alt {
			align-items: flex-start;
			bottom: 30px;
			left: 30px;
			max-width: calc(100% - 40px);
			top: auto;

			@media (max-width: 1024px) {
				bottom: 20px !important;
				left: 20px !important;
				top: auto !important;
			}
		}

		&--alt2 {
			bottom: 30px;
			left: 33px;
			max-width: calc(100% - 40px);
			top: auto;

			@media (max-width: 1024px) {
				bottom: 20px !important;
				left: 20px !important;
				top: auto !important;
			}
		}

		&--center {
			&.hpBucketWrapper__overlay {
				bottom: 100px;
				justify-content: center;
				left: 0;
				max-width: 100%;
				right: 0;
				top: auto;

				@media (max-width: 1200px) {
					bottom: 80px;
				}

				@media (max-width: 1024px) {
					bottom: 60px;
				}
			}
		}

		&--left {
			align-items: flex-start;
		}

		&--wide {
			left: 85px;
			max-width: 40%;
			width: 503px;

			@media (max-width: 1144px) {
				left: 70px;
			}

			@media (max-width: 1024px) {
				left: 60px;
			}

			.hpBucketWrapper__overlay {

				&-img {
					width: 450px;
				}
				
				&-btn {
					width: 260px;
				}

				@media (max-width: 1366px) {
					&-subtext {
						font-size: 2.7rem;
					}
				}

				@media (max-width: 1144px) {
					&-subtext {
						font-size: 2.5rem;
					}
				}

				@media (max-width: 1065px) {
					&-subtext {
						font-size: 2.2rem;
					}
				}
			}
		}

		@media (max-width: 1024px) {
			left: 50px;
			top: 50px;
		}

		@media (max-width: 980px) {
			left: 40px;
			top: 40px;
		}

		&-subtext {
			color: #fff;
			font-size: 3rem;
			font-weight: 100;
			margin-bottom: 30px;
			text-shadow: var(--home-banner-drop-shadow);
		}

		&-linkTxt {
			font: 400 6.5rem var(--fontSerif);
			margin-bottom: 25px;
			text-decoration: none;
			text-shadow: var(--home-banner-drop-shadow);

			@media (max-width: 1024px) {
				font-size: 6rem;
			}

			.hpBucketWrapper & {
				color: #fff;
				line-height: normal;
				text-transform: none;
			}

			&--small {
				font-size: 4.5rem;
			}

			&--xSmall {
				font-size: 3.7rem;
			}

			&--nowrap {
				white-space: nowrap;
			}
		}

		&-img {
			display: block;
			margin-bottom: 20px;
			max-height: calc(100% - 20px);
			width: auto;
		}

		&-btn {
			align-items: center;
			display: flex;
			font-size: 2rem;
			font-weight: 500;
			justify-content: center;
			height: 45px;
			max-width: 100%;
			padding: 6px 20px;
			white-space: nowrap;
			width: 150px;
			
			.hpBucketWrapper & {
				display: flex;
				line-height: normal;
				text-transform: none;
			}

			&--small {
				font-size: 2rem;
				height: 45px;
				padding: 6px 20px;
				width: 150px;
			}
		}

		&--minkaBtn {
			background: #35641b;
		}
	}

	&__adjacentLayout {
		--adjacentLayout-link-size: 2rem;
		--adjacentLayout-text-padding: 35px 50px;
		--adjacentLayout-text-size: 3rem;
		--adjacentLayout-text-width: 33.33%; // Defaults to 1/3, create a modifier class if new % is needed
		--adjacentLayout-title-size: 5rem;
		position: relative;

		@media (max-width: 1440px) {
			--adjacentLayout-text-size: 2.6rem;
			--adjacentLayout-title-size: 4.6rem;
		}

		@media (max-width: 1280px) {
			--adjacentLayout-link-size: 1.8rem;
			--adjacentLayout-text-size: 2.0rem;
			--adjacentLayout-title-size: 4rem;
		}

		@media (max-width: 1180px) {
			--adjacentLayout-link-size: 1.7rem;
			--adjacentLayout-text-size: 1.9rem;
			--adjacentLayout-title-size: 3.5rem;
		}

		@media (max-width: 1100px) {
			--adjacentLayout-text-padding: 35px 45px;
		}

		@media (max-width: 1024px) {
			--adjacentLayout-text-padding: 30px 41px;
		}

		&-text {
			--adjacentLayout-hover-color: var(--red);
			--adjacentLayout-text-color: var(--baseTextColor);
			background: #fff;
			box-sizing: border-box;
			height: 100%;
			padding: var(--adjacentLayout-text-padding);
			position: absolute;
			width: var(--adjacentLayout-text-width);

			&--light {
				--adjacentLayout-hover-color: #fff;
				--adjacentLayout-text-color: #fff;
			}
			
			#homeLightingCatalogBanner & {
				background: #906F5A;
			}

			#homeLightingDesignServicesBanner & {
				background: #504d36;
			}
		}

		&-title {
			color: var(--adjacentLayout-text-color);
			letter-spacing: -0.03rem;

			.hpBucketWrapper & {
				font: 400 var(--adjacentLayout-title-size) var(--fontSerif);
				margin-bottom: 20px;
			}
		}

		&-subtext {
			color: var(--adjacentLayout-text-color);
			font-size: var(--adjacentLayout-text-size);
			font-weight: 100;
			margin-bottom: 15px;
		}

		&-links {
			a {
				color: var(--adjacentLayout-text-color);
				display: inline-block;
				font-size: var(--adjacentLayout-link-size);
				font-weight: 500;
				line-height: 2.8;
				text-transform: none;
				white-space: nowrap;

				@media (max-width: 1024px) {
					line-height: 2.4;
				}

				@media (max-width: 980px) {
					line-height: 2.2;
				}

				.hpBucketWrapper__adjacentLayout {
					&-linkTxt,
					&-arrow {
						line-height: inherit;
					}
				}

				&:hover {
					color: var(--adjacentLayout-hover-color);

					.hpBucketWrapper__adjacentLayout-linkTxt {
						text-decoration: underline;
					}
				}
			}
		}

		&-img {
			margin-left: var(--adjacentLayout-text-width);

			img {
				display: block;
			}
		}
	}
}

.openBoxBannerHeader {
	text-align: center;
}

.hpDescs {
	margin-top: 35px;

	.bucketText3Across.hpBucketWrapper {
		margin-top: 25px;

		.bucketText3Across-boxLink {
			h2 {
				font-size: 1.4em;
			}

			margin-left: 25px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.bucketBanner.hpBucketWrapper {
	&.hpBucketWrapperTrackLighting {
		.copyContainer--furnitureSale {
			.saleCopyReDesign {
				margin-top: 15px;
			}

			.headingMargin {
				margin-top: 20px;
			}
		}
	}
}

.shopByRoomLanding__buckets {
	.hpBucketHoverImage {
		.hpBucketHoverImageDiv {
			overflow: hidden;

			&:hover {
				img {
					transform: scale(1.05);
				}
			}
		}

		img {
			transition: 400ms ease-in-out;
		}

		&:hover {


			.shopByRoomLanding__headingReDesign {
				color: #000;
			}
		}

		.buttonRightArrow {
			display: inline-block;
			transition: 300ms ease-in-out;
		}

		.hpBucketWrapperTitle {
			line-height: unset;
		}

		.shopByRoomLanding__headingReDesign:hover {

			.buttonRightArrow {
				color: #000;
				transform: translateX(10px);
			}

			.hpBucketWrapperTitle {
				color: var(--red);
				text-decoration: underline;
			}
		}
	}

}

.hpBucketWrapperServiceBanner {
	a {
		overflow: hidden;
	}

	.bannerImageReDesign--shortContainer {
		transition: 400ms ease-in-out;

		&:hover {
			transform: scale(1.05);
		}
	}
}

.shopByRoomLandingBrowseBtn {
	.hpBucketWrapperTitle {
		& + span {
			display: inline-block;
			transition: 400ms ease-in-out;
		}
	}

	&:hover {
		.hpBucketWrapperTitle {
			color: var(--red);
			text-decoration: underline;

			& + span {
				color: #000;
				transform: translateX(10px);
			}
		}
	}
}

.topBrandsItemsWrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 15px 0 20px;
	text-align: center;

	.topBrandsItem {
		flex-basis: 100%;
		margin: 0 24px;
	}

	.topBrandsBottom {
		padding-bottom: 25px;
	}
}