// SCSS Variable declarations only
$lpRed: #c00;
$highlightRed: #c00;
$lpBlue: #06b;
$lpPurple: #8f5b96;
$errorRed: #c00;

$baseFontColor: #333;

// html font size; do not use in other tags
// 62.5% = 10px (based on 16px browser default font size)
$baseHtmlFontSize: 62.5%;

$baseFontSize: 1.4rem; // body font-size
$baseFontLineHeight: 1.5; // unitless (em), so that changes to font-size will give a proportionate line-height
$baseSmallFontSize: 1.1em;
$fieldHighlightWidth: 2px;

$lpContainerMinWidth: 960px;
$lpContainerMaxWidth: 1440px;
$lpHeaderMaxWidth: 1920px;
$lpHeaderMinBreakpoint: 1441px;
$responsiveSideGutter: 30px;
$scrollbarWidth: 20px;
$fieldHeight: 44px;
$elementAnimationDuration: 250ms;
$elementTimingFunction: ease-in-out;
$errorBackgroundColor: #ffebeb;
$errorBorderColor: $lpRed;
$errorTextColor: $lpRed;
$checkboxfieldBorderColor: #595959;
$checkboxBorderColorChecked: $lpBlue;
$checkboxBackgroundColorChecked: $lpBlue;
$radioBorderColorChecked: $lpBlue;
$radioBackgroundColorChecked: $lpBlue;
$fieldHighlightColor: $lpBlue;
$fieldBorderColor: #999;
$fieldHighlightColorInvalid: #c00;
$radioBorderColorChecked: $lpBlue;
$radioBackgroundColorChecked: $lpBlue;
$blueLink: $lpBlue;
$fieldHighlightColorCheckboxRadio: #38f;
$categoryNavHoverBottomBorderWidth: 5px; // Active Category bottom border width
// Mixins

// Clearfix Mixin
@mixin clearfix {
	&::after {
		clear: both;
		content: '';
		display: table;
	}
}

// Mixin for responsive layout styles
@mixin responsiveWidthLayout(
	$minWidth: $lpContainerMinWidth,
	$maxWidth: $lpContainerMaxWidth,
	$gutterMultiplier: 2,
	$hasGutter: true,
	$setFixedWidthOnMediaQuery: true
) {
	box-sizing: content-box;
	margin: 0 auto;
	max-width: $maxWidth;
	min-width: $minWidth;
	@if $hasGutter == true {
		width: calc(100% - #{$responsiveSideGutter * $gutterMultiplier});
	} @else {
		width: 100%;
	}

	@if $setFixedWidthOnMediaQuery == true {
		// strict width set with natural gutters until theres enough room to display set gutter width on both sides
		@media (max-width: $lpContainerMinWidth) {
			width: $lpContainerMinWidth;
		}
	}
}

@mixin responsive-banner($url, $height: 393px, $bgSize: cover) {
	background-image: url($url);
	background-position: center;
	background-repeat: no-repeat;
	background-size: $bgSize;
	height: $height;
}

@mixin responsiveListItemMargins($listItemWidth, $listItemsPerRow) {
	$listItemMargin: calc((100% - #{$listItemWidth * $listItemsPerRow}) /#{$listItemsPerRow * 2});
	margin-left: $listItemMargin;
	margin-right: $listItemMargin;
}

@mixin flexNavLinksWithDivider($containerMinWidth, $containerMaxWidth, $offsetWidth: 64px) {
	display: flex;
	flex-direction: row;
	font: bold 1.8rem var(--fontHeading);
	justify-content: space-between;
	margin: 32px auto 3px;
	max-width: $containerMaxWidth;
	min-width: $containerMinWidth;
	width: 100%;

	a {
		padding: 3px 15px;
		text-decoration: none;
		white-space: nowrap;

		&.active {
			background: #373737;
			color: #fff;
		}
		@media (max-width: $lpContainerMinWidth + $offsetWidth) {
			padding: 3px 10px;
		}
	}

	li {
		border-right: 1px solid #ddd;
		flex-basis: auto;
		flex-grow: 1;
		line-height: 1rem; // to make the right border shorter than the text
		text-align: center;

		&:first-child {
			margin-left: -1%;
		}

		&:last-child {
			border-right: 0;
			margin-right: -1%;
		}
		@media (max-width: $lpContainerMinWidth + $offsetWidth) {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				border-right: 0;
				margin-right: 0;
			}
		}
	}
}

@mixin retinaBorderWidthHalfPixel {
	@media (min-device-pixel-ratio: 2) {
		& {
			border-width: 0.5px;
		}
	}
	@media (min-device-pixel-ratio: 3) {
		& {
			border-width: 1px;
		}
	}
}

// Only use this to lazy-loaded images that have text overlay and have no min-height set
// to show skeleton UI and prevent overlapping text; Add new modifier classes in the future as needed
@mixin insertLazyImageWithTextOverlayCSS() {
	.lazyImageWithTextOverlay {
		&--500 {
			min-height: 500px;
		}
		&--400 {
			min-height: 400px;
		}

		&--300 {
			min-height: 300px;
		}

		&--30 {
			min-height: 30px;
		}
	}
}
