@import '../global/style-guide';
@import '../global/bucket-styles';

:root {
	--home-banner-drop-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

#lpContainer {
	@include responsiveWidthLayout;

	&.isHospitality {
		margin: 0 auto;
		width: 960px;
	}
}

.hpWrapper {
	@include responsiveWidthLayout($hasGutter: false);
	display: block;

	&.hospitalityWrapper {
		min-width: 912px;
	}
}

.hpSliders,
.hpSlider {
	margin: 0 auto;
	min-width: $lpContainerMinWidth;
	width: 100%;

	.positionRelative {
		position: relative;
	}

	.hpDate {
		bottom: 13px;
		color: white;
		margin-right: 8px;
		position: absolute;
		right: 0;
	}

	&--withOverlay {
		position: relative;
		z-index: 1;

		.overlayImage {
			left: 30px;
			position: absolute;
			top: 30px;
			z-index: 2;
		}
	}

	@keyframes crossFade {
		/*
			At duration of 6s this is approximately:
			2.5s of delay
			7s of transition
			2.5s of delay
		*/
		0% {
			opacity: 0;
		}

		20% {
			opacity: 0;
		}

		80% {
			opacity: 1;
		}

		100% {
			opacity: 1;
		}
	}

	&--split {
		display: flex;
		margin: auto;
		max-width: 1920px;

		.homepage-splash-banner-large,
		video {
			display: inline-flex;
			flex-basis: 50%;
			max-width: 50%;
		}

		.homepage-splash-banner-full {
			width: 100%;
		}
	}

	.homepage-splash-banner {
		display: block;
		margin: 0 auto;
		max-width: 1440px;

		&--ds {
			color: #000;
		}

		&--background-ds {
			background-image: url('/images/index/ads/12-25-2021-default/hp/Non-sale-splash.jpg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: auto 100%;
			max-width: 1440px;
			padding-top: 26.770833%;
			position: relative;

			@media screen and (min-width: 1165px) {
				margin: 0 auto;
				max-width: 1440px;
				padding-top: 312px;
			}
		}

		&--innerWrapper {
			height: 100%;
			left: 0;
			margin: 0 auto;
			max-width: 1280px;
			min-width: 960px;
			overflow: hidden;
			position: relative;
			top: 0;
			width: calc(100% - 120px);
		}

		&--innerWrapper-ds {
			height: 100%;
			left: 0;
			margin: 0 auto;
			max-width: 1280px;
			min-width: 960px;
			overflow: hidden;
			position: absolute;
			right: 0;
			top: 0;
			width: calc(100% - 120px);

			.ds_main-heading {
				color: #000;
				font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
				font-size: clamp(70px, 7vw, 101px);
				font-weight: 600;
				letter-spacing: 0.035em;
				line-height: 1;
				margin: 50px 0 0;
				text-transform: uppercase;
			}

			.ds_savings {
				font-family: var(--fontHeading);
				font-size: clamp(17px, 2.1vw, 30px);
				text-decoration: none;
			}

			.btnDS {
				background-color: #000;
				color: #fff;
				font-size: 17.5px;
				height: 42px;
				margin-top: 25px;
				vertical-align: middle;
				width: 166px;
			}
		}

		&--overlay {
			left: 0;
			position: absolute;
			top: 36.5px;
			z-index: 3;
		}

		&--overlayLarge {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			left: 60px;
			max-height: calc(100% - 20px);
			max-width: 30%;
			min-width: 200px;
			pointer-events: none; // let click go through underneath the banner itself
			position: absolute;
			top: 0;
			z-index: 1;

			@media (max-width: 1200px) {
				max-width: 25%;
			}

			@media (max-width: 1024px) {
				left: 50px;
			}

			@media (max-width: 980px) {
				left: 40px;
			}

			> img {
				display: block;
				margin-bottom: 15px;
				width: 100%;
			}

			> .calloutBtn {
				align-items: center;
				display: flex;
				font-size: 2rem;
				font-weight: 500;
				height: 45px;
				justify-content: center;
				max-width: 100%;
				padding: 6px 20px;
				text-transform: none;
				white-space: nowrap;
				width: 150px;
			}
		}

		&--splash {
			max-height: 632px;
			min-width: 1024px;

			@media screen and (max-width: 1025px) {
				min-width: unset;
				width: 100%;
			}
		}

		.splash--logo {
			left: 4.5%;
			max-height: 444px;
			max-width: 396px;
			min-height: 322px;
			min-width: 301px;
			position: absolute;
			top: 10%;

			&--small {
				min-height: 0;
			}

			@media screen and (max-width: 1200px) {
				max-width: 280px;
			}
		}

		.homeFurnishingsSaleSplash--logo {
			left: 60px;
			min-height: 280px;
			min-width: 250px;
			top: 60px;
		}

		&-large {
			max-width: 1920px;

			.homepage-splash-banner--splash {
				max-height: 772px;
			}

			&.secondSplash {
				.homepage-splash-banner--splash {
					max-height: 186px;
					min-height: unset;
				}
			}

			.splash--logo {
				max-height: 444px;
				max-width: 1960px;

				@media screen and (max-width: 1440px) {
					max-width: 335px;
				}

				@media screen and (max-width: 1200px) {
					max-width: 280px;
				}
			}
		}

		&-xlarge {
			max-width: 100%;

			.homepage-splash-banner--splash {
				max-height: none;
				width: 100%;
			}
		}
	}
}

.hpSplashGrid {
	align-items: stretch;
	display: flex;
	gap: 20px;
	margin: 0 auto;
	max-width: 1920px;

	&--grid {
		display: grid;
		grid-template-columns: minmax(0, 1280px) 30%;
		height: auto;
		width: 100%;

		.hpSplashGrid {
			&__image {
				display: block;
				height: auto;
				width: 100%;

				> img {
					height: 100%;;
				}

				&Overlay {
					@media (max-width: 1700px) {
						left: 45px;
					}

					@media (max-width: 1500px) {
						left: 40px;
					}

					@media (max-width: 1000px) {
						left: 35px;
					}

					@media (max-width: 1200px) {
						left: 30px;
					}

					@media (max-width: 1000px) {
						left: 25px;
					}

					&--logo {
						filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.4));
					}
				}
			}

			&__buckets {
				align-items: center;
				display: flex;
				flex-direction: column;
				gap: 20px;
				height: 100%;
				justify-content: space-between;
			}

			&__bucket {
				align-items: center;
				display: flex;
				height: 50%;
				justify-content: center;
				width: 100%;
			}

			&__bucket {
				&Heading,
				&Subheading {
					line-height: 1.3;
				}

				@media (max-width: 1500px) {
					&Heading {
						font-size: 5rem;
					}

					&Subheading {
						font-size: 2.3rem;
					}
				}

				@media (max-width: 1420px) {
					&Heading {
						font-size: 4.8rem;
					}

					&Subheading {
						font-size: 2.1rem;
					}
				}

				@media (max-width: 1300px) {
					&Heading {
						font-size: 4.1rem;
					}

					&Subheading {
						font-size: 1.8rem;
					}
				}

				@media (max-width: 1200px) {
					&Heading {
						font-size: 3.3rem;
					}

					&Subheading {
						font-size: 1.4rem;
					}
				}

				@media (max-width: 1100px) {
					gap: 5px;
					padding: 10px 0;

					&Heading {
						font-size: 3rem;
					}

					&Subheading {
						font-size: 1.3rem;
					}
				}

				&Btn {
					@media (max-width: 1366px) {
						padding-bottom: 0;
					}

					@media (max-width: 1100px) {
						font-size: 1.5rem;
						height: 35px;
						padding: 0;
					}
				}
			}
		}
	}

	&__video,
	&__image,
	&__text {
		flex: 1280px;
		position: relative;
		width: 100%;

		a {
			display: block;
		}

		video,
		> img {
			display: block;
			height: auto;
			width: 100%;
		}

		&Overlay {
			align-items: center;
			bottom: 0;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			left: var(--overlay-left, 30px);
			min-width: 200px;
			position: absolute;
			top: 0;
			width: var(--overlay-width, 36%);
			z-index: 1;

			&--center {
				gap: var(--overlay-gap, 10px);
				width: var(--overlay-width, 100%);

				.hpSplashGrid__bucketSubheading {
					margin-bottom: 15px;
				}
			}

			&--topLeft {
				gap: 30px;
				justify-content: flex-start;
				left: var(--overlay-left);
				top: var(--overlay-top);
			}

			&--logo {
				filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));
			}

			.hpSplashGrid__bucketBtn {
				box-shadow: var(--home-banner-drop-shadow);
			}
		}
	}

	&__buckets {
		display: flex;
		flex: 623px;
		flex-direction: column;
		gap: 20px;
	}

	&__bucket {
		align-items: center;
		background: var(--bgImage) center/cover no-repeat;
		background-color: var(--bgColor);
		box-sizing: border-box;
		color: #fff !important;
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		text-align: center;
		text-decoration: none;

		&Heading {
			font: 400 5.3rem var(--fontSerif);

			.hpSplashGrid__image & {
				text-shadow: var(--home-banner-drop-shadow);
			}
		}

		&Subheading {
			font-size: 2.5rem;

			.hpSplashGrid__image & {
				text-shadow: var(--home-banner-drop-shadow);
			}
		}

		@media (max-width: 1366px) {
			&Heading {
				font-size: 5rem;
			}

			&Subheading {
				font-size: 2.3rem;
			}
		}

		@media (max-width: 1300px) {
			&Heading {
				font-size: 4.3rem;
			}

			&Subheading {
				font-size: 2rem;
			}
		}

		@media (max-width: 1100px) {
			&Heading {
				font-size: 3.5rem;
			}

			&Subheading {
				font-size: 1.6rem;
			}
		}

		&Btn {
			align-items: center;
			display: flex;
			font-size: 1.7rem;
			font-weight: 500;
			height: 45px;
			justify-content: center;
			max-width: 100%;
			padding: 6px 20px;
			text-transform: none;
			white-space: nowrap;
			width: 150px;

			&--minka {
				background-color: #35641b;
				border: none;
				color: #fff;
				font-size: 1.7rem;
				height: 45px !important;
				padding: 0;
				width: 150px !important;

				&:hover {
					background-color: #35641b;
					color: #fff;
				}
			}

			@media (max-width: 1366px) {
				height: 40px;
				width: 140px;
			}
		}

		&:has(.hpSplashGrid__bucketBannerImg) .hpSplashGrid__bucketBtn {
			box-shadow: var(--home-banner-drop-shadow);
		}
	}
}

.hpSlider {
	position: relative;

	&__overlay {
		align-items: center;
		display: flex;
		flex-direction: column;
		left: 60px;
		max-height: calc(100% - 20px);
		max-width: 30%;
		min-width: 200px;
		pointer-events: none; // let click go through underneath the banner itself
		position: absolute;
		top: 60px;
		z-index: 1;

		@media (max-width: 1024px) {
			left: 50px;
			top: 40px;
		}

		@media (max-width: 980px) {
			left: 40px;
			top: 40px;
		}

		&--center-y {
			height: 100%;
			justify-content: center;
			top: 0;
		}

		&-linkImg {
			margin-bottom: 15px;
			width: 100%;
		}

		&-img {
			display: block;
			max-width: 458px;
			width: 100%;
		}

		&-btn {
			align-items: center;
			display: flex;
			font-size: 2rem;
			font-weight: 500;
			height: 45px;
			justify-content: center;
			max-width: 100%;
			padding: 6px 20px;
			text-transform: none;
			white-space: nowrap;
			width: 150px;
		}
	}
}

.hpVideoContainer {
	margin: 0 auto;
	position: relative;
	width: 100%;

	a {
		display: block;
	}

	video {
		display: block;
		height: auto;
		width: 100%;
	}

	.hpVideoOverlay {
		align-items: center;
		display: flex;
		flex-direction: column;
		left: 60px;
		max-height: calc(100% - 20px);
		max-width: 30%;
		min-width: 200px;
		pointer-events: none; // let click go through underneath the banner itself
		position: absolute;
		top: 60px;
		z-index: 1;

		@media (max-width: 1024px) {
			left: 50px;
			top: 40px;
		}

		@media (max-width: 980px) {
			left: 40px;
			top: 40px;
		}

		&--center {
			bottom: 100px;
			justify-content: center;
			left: 0;
			max-width: 100%;
			right: 0;
			top: auto;

			@media (max-width: 1200px) {
				bottom: 80px;
			}

			@media (max-width: 1024px) {
				bottom: 60px;
			}

			&-y {
				height: 100%;
				justify-content: center;
				top: 0;
			}
		}

		&__overlay-linkTxt {
			color: #fff;
			font: 400 6.5rem var(--fontSerif);
			margin-bottom: 25px;
			text-decoration: none;
			text-shadow: var(--home-banner-drop-shadow);
			text-transform: none;

			@media (max-width: 1024px) {
				font-size: 6rem;
			}
		}

		&__overlay-linkImg {
			margin-bottom: 15px;
			width: 100%;
		}

		&__overlay-img {
			display: block;
			max-width: 458px;
			width: 100%;
		}

		&__overlay-btn {
			align-items: center;
			box-shadow: var(--home-banner-drop-shadow);
			display: flex;
			font-size: 2rem;
			font-weight: 500;
			height: 45px;
			justify-content: center;
			max-width: 100%;
			padding: 6px 20px;
			text-transform: none;
			white-space: nowrap;
			width: 150px;
		}
	}
}

.hpTitle {
	display: block;
	font-size: 3.6rem;
	font-weight: normal;
	margin: 40px 0 0;
	text-align: center;
}

.hpTitleReDesign {
	display: block;
	font-size: 4rem;
	font-weight: 400;
	letter-spacing: -0.04em;
	margin: 15px 0;
	text-align: center;
}

.hpBucketWrapper {
	@extend %bucketStyles;

	.buttonrightarrow {
		line-height: inherit;
	}

	&--seatingSale {
		.copyWrapper .copyContainer.copyContainer--topWrapper {
			.saleCopyReDesign {
				margin-left: auto;
				max-width: 370px;
			}

			.saleCopyReDesignCF {
				margin-left: 55px;
				max-width: unset;
			}

			.headingMargin,
			.hpBucketWrapperML,
			.titleReDesign {
				margin-right: 35px;
			}

			.saleCopyReDesignNS {
				margin-left: auto;
				max-width: 420px;
			}

			.saleCopyReDesignFS.newSaleRedesign {
				margin-right: 35px;
				max-width: 100%;
			}

			.subLinks.subLinkMargin {
				margin-left: auto;
				max-width: 425px;

				&.subLinksContainer {
					margin-left: 55px;
				}
			}
		}
	}

	&.hpFullWidthBanner {
		img {
			max-height: 420px;
		}

		&--short img {
			max-height: 340px;
		}

		&--tall img {
			max-height: 570px;
		}

		&--pros img {
			max-height: 60px;
			max-width: 400px;
		}
	}

	&--bgc {
		background-color: #f1f1f1;
	}

	&--flex {
		display: flex !important;
	}

	.pros {
		margin-left: 10%;

		p {
			color: #000;
			font-size: 1.5rem;
			padding-bottom: 5px;
			text-transform: none;
		}
	}

	&__iframeVideo {
		aspect-ratio: 9/6;
		display: block;
		height: auto;
		object-fit: cover;
		width: 100%;
	}

	&.bucket2Across {
		.hpBucketWrapper__iframeVideo {
			aspect-ratio: 612/410;
			max-height: 410px;
		}

		&.bucket2Across--withVideo {
			> div {
				width: 50%;

				&:nth-child(2) {
					display: flex;
					justify-content: right;
				}
			}

			img {
				aspect-ratio: 612/410;
				max-width: 612px !important;
			}

			a {
				width: 100%;
			}
		}
	}
}

@include insertLazyImageWithTextOverlayCSS;

.hpWhy {
	border-top: 1px solid $borderGrey;
	clear: both;
	font-family: var(--fontHeading);
	margin: 20px 0 0;
	padding: 15px 0;
	width: 100%;

	h3 {
		color: #000;
		font-size: 1.8rem;
	}

	p {
		margin: 10px 0;
	}
}

.hpMoreYouMayLike {
	margin: 26px 0;
	width: 100%;

	.sortMoreYouMayLikeTitle {
		font-size: 1.7rem;
	}

	#certonaItems {
		justify-content: space-around;
		margin-top: 16px;
		padding: 0;
	}

	.sortMYMLProdContainer {
		a {
			padding: 0 20px 0 0;
		}
	}
}

.bucket3Across {
	&.hpBucketWrapper {
		&.widerMiddleCol {
			div img {
				margin-bottom: 10px;
			}

			div:first-child {
				margin-left: 0;
				margin-right: 24px;
				width: 210px;
			}

			div:nth-child(2) {
				margin-left: 0;
				margin-right: 24px;
				width: 444px;
			}

			div:last-child {
				margin-left: 0;
				width: 210px;
			}
		}
	}
}

// shop by room landing page section
.shopByRoomLanding {
	margin: 40px auto 0;
	width: 100%;

	&__head {
		h1 {
			font-size: 3.6rem;
			font-weight: 500;
			letter-spacing: 1px;
			margin: 0 0 20px;
		}
	}

	@media (min-width: 1280px) {
		&__headSubtext {
			font-size: 2.2rem;
		}
	}

	&__headSubtextReDesign {
		font-family: var(--fontHeading);
		font-size: min(max(1.7vw, 1.8rem), 2.3rem);
		font-weight: 400;
		margin-bottom: -20px;
	}

	&__browseAll {
		font-size: 82%;
		font-weight: 500;
		letter-spacing: 0.05em;
		text-decoration: none;
	}

	&__browseAllReDesign {
		font-family: var(--fontHeading);
		font-size: min(max(1.7vw, 1.8rem), 2.3rem);
		font-weight: 500;
		text-decoration: none;
	}

	&__headingReDesign {
		font-family: var(--fontHeading);
		font-size: min(max(1.7vw, 1.8rem), 2.3rem);
		font-weight: 500;
		margin-top: 10px;
		text-align: left;
		text-decoration: none;
	}

	&__messageReDesign {
		font-family: var(--fontHeading);
		font-size: min(max(1.4vw, 1.5rem), 2rem);
		font-weight: 400;
		margin: -10px 0 0;
		padding: 0;
		text-align: left;
		text-transform: none;
		width: 100%;
	}

	h2 {
		margin-bottom: 10px;
	}

	&__semisale {
		a {
			&:hover {
				color: var(--baseTextColor);

				p {
					color: var(--red);
				}
			}
		}
	}

	a {
		color: #000;
	}
}

// your store
.hpMyStore {
	border-top: 1px solid #999;
	font-family: var(--fontHeading);
	margin: 70px auto 0;

	a {
		color: #06b;
		font-size: 1.2rem;
		text-decoration: none;

		&:hover {
			color: #000;
		}
	}

	.top {
		background: #fff;
		display: inline-flex;
		padding-right: 15px;
		position: relative;
		top: -17px;
	}

	.storeHeader {
		font: 500 1.8rem var(--fontHeading);
		margin-bottom: 15px;
		text-align: center;
		text-transform: uppercase;
	}

	.storeName {
		@extend .storeHeader;
		font-weight: 500;
		text-transform: uppercase;
	}

	.separator {
		color: #333;
		font-size: 1.8rem;
		line-height: 22px;
		margin: 0 10px;
	}

	.changeStore a {
		position: relative;
		top: 2px;
	}

	.content {
		display: flex;

		.mapContainer {
			left: 0;
			margin-right: 20px;
			position: relative;
			top: 0;
			width: 370px;

			.mapImage {
				height: 100%;
				left: 0;
				object-fit: cover;
				position: relative;
				top: 0;
				width: 100%;
			}

			.copyright {
				bottom: 1%;
				position: absolute;
				right: 1%;
				width: 40%;
			}

			.logo {
				bottom: 1%;
				left: 1%;
				position: absolute;
				width: 20%;
			}
		}

		.info {
			border-right: 1px solid #999;
			flex-basis: 25%;
			margin-right: 30px;
		}

		.designServices {
			flex-basis: 35%;

			> * {
				display: block;
				margin-bottom: 15px;
			}

			strong {
				text-transform: uppercase;
			}

			span {
				width: 75%;
			}
		}

		.agentImage {
			flex-shrink: 0;
			height: 191px;
			margin-right: 25px;
			width: 137px;
		}
	}

	ul {
		list-style: none;
		margin: 0;

		li:not(:last-child) {
			margin-bottom: 10px;
		}

		.hours {
			font-size: 14px;
			font-weight: 500;
		}

		.column {
			display: inline-block;
			width: 100%;

			@media (min-width: 1200px) {
				width: 50%;
			}
		}
	}
}

#lastCategoryContainer {
	.priceCallout {
		font-size: 1.5rem;
		font-weight: 500;
		margin-top: 10px;

		&.salePrice {
			color: var(--darkRed);
		}
	}
}

.lastCategoryTitle {
	font: 500 1.4rem var(--fontHeading);
	margin-bottom: 20px;
	margin-left: 10px;
}

.instagramFeed--4Across {
	max-width: 1440px;

	#pixleeWidget {
		box-sizing: border-box;
		margin: 0 auto;
		position: relative;
		width: 100%;
	}

	.Carousel {
		display: flex;
		justify-content: center;
	}

	.HomeWidget {
		aspect-ratio: 1360 / var(--react-multi-carousel-list-max-height);
		box-sizing: border-box;
		max-height: var(--react-multi-carousel-list-max-height);
		max-width: calc(100% - 80px);
		width: 100%;

		&:not(.skeleton, :has(.skeleton)) {
			height: 100%;
		}

		&.skeleton {
			height: var(--react-multi-carousel-list-max-height);
			margin: 0 auto;
		}
	}
}

#lpContainer {
	@media screen and (max-width: 1025px) {
		#widgetContainer {
			overflow-x: hidden;
		}
	}
}

.seachBoxHome {
	align-items: center;
	column-gap: 4rem;
	display: flex;
	justify-content: center;
	margin-bottom: 6rem;
	padding: 28px 0;

	.searchContainer {
		margin: 1px 0 0 20px;

		.searchInputResponsive {
			background: #fff;

			@media (max-width: 1024px) {
				width: 287px;
			}
		}
	}

	.searchArrowBtn {
		background: #fff;

		&::before {
			top: 11px;
		}
	}

	p {
		color: #000;
		font-family: var(--fontHeading);
		font-size: 18px;
		font-weight: 500;
		margin: 0;
	}

	span {
		font-weight: normal;
		padding-left: 6px;
	}
}

#hpDailySales {
	position: relative;

	.homepage-splash-banner {
		max-width: 1920px;
		overflow: hidden;

		.homepage-splash-banner--background-ds {
			background-image: unset;
			padding-top: 313px;

			.btnDS {
				border-radius: 5px;
			}

			.ds_main-heading {
				font-size: clamp(70px, 92px, 101px);
			}

			.ds_savings {
				font-size: clamp(17px, 30.1px, 30px);
			}
		}

		.homepage-splash-banner--splash {
			height: 100%;
			left: 50%;
			max-width: calc(100% - 60px);
			position: absolute;
			top: 1px;
			transform: translateX(-50%);

			@media (orientation: portrait) {
				max-width: calc(100% - 20px);
			}
		}
	}
}

.bucketBanner.hpBucketWrapper .copyWrapper {
	.copyContainer.furnitureSale--container {
		.alignLeft {
			margin-left: 40px;
		}

		.subLinks.alignLeft {
			max-width: 430px;
		}
	}
}

.homeTrendingProducts {
	margin: 60px 0 25px;

	h2 {
		align-items: baseline;
		color: #000;
		display: flex;
		font: normal 2rem var(--fontHeading);
		letter-spacing: -0.04em;
		margin-bottom: 25px;
		text-transform: capitalize;

		span {
			position: relative;

			&::after {
				bottom: 1px;
				content: '|';
				cursor: none;
				font-size: 14px;
				position: absolute;
				right: -14px;
			}
		}

		a {
			color: var(--baseTextColor);
			font: normal 14px var(--fontHeading);
			margin-left: 20px;
			text-decoration: underline;

			&:hover {
				color: var(--red);

				&::before {
					color: var(--baseTextColor);
				}
			}
		}
	}

	.homeTrendingProductsContainer {
		margin: 0 -9px;

		.glider-track {
			align-items: flex-start;
			margin: -5px;
		}

		.homeTrendingProductsItem {
			margin: 4px 6px;
			text-align: left;

			a {
				box-sizing: border-box;
				display: inline-block;
				margin: 5px;
				padding: 5px;
				text-decoration: none;
				width: 100%;

				&:hover {
					color: var(--red);
				}
			}

			.productTitle {
				box-orient: vertical;
				display: box;
				font-size: 12px;
				font-weight: normal;
				height: 35px;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-word;
			}

			.productPrice {
				font-size: 14px;
				font-weight: 500;
				margin: 5px 0;

				&.salePrice {
					color: #900;
				}
			}

			img {
				border-radius: 5px;
				margin-bottom: 5px;
				max-width: 100%;
			}

			.productSummaryRating {
				&.hideVisibility {
					display: none;
				}

				.goldReviewStars {
					--goldReviewStarsSize: 16px;
					justify-content: flex-start;
				}
			}
		}
	}
}

.homeSection {
	margin-top: 60px;

	&--margin-top-l {
		margin-top: 70px;
	}

	&--margin-top-s {
		margin-top: 20px;
	}

	&__title {
		font: 400 5rem var(--fontSerif);
		letter-spacing: -0.04rem;
		margin-bottom: 25px;
		text-align: center;

		&--margin-bottom-l {
			margin-bottom: 40px;
		}

		a {
			text-decoration: none;
		}
	}

	&__bottomCopy {
		h2 {
			font: 400 2.8rem var(--fontSerif);
		}

		p {
			font-size: 1.6rem;
		}
	}

	&__viewMore {
		display: block;
		font-size: 1.8rem;
		font-weight: 500;
		margin-top: 20px;
		padding-right: 20px;
		text-align: right;
		text-decoration: none;
		text-transform: none;

		&:hover {
			color: var(--red);

			span:not([aria-hidden='true']) {
				text-decoration: underline;
			}
		}
	}

	&:has(.bucketTextAcross .bucketTextAcross__viewMore) {
		padding-bottom: 70px;
	}

	&--textCenter {
		text-align: center;
	}
}

#mainContent {
	&:has(.hpSplashGrid--withNonSaleBanner) #homeLightingCatalogBanner {
		display: none;
	}

	&:has(.hpSplashGrid--winterSaleBanner) {
		#homeLightingDesignServicesBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/12-26-2024/hp/Banner-Snowflake-Desktop-DS.jpg') center/cover no-repeat;
			background-color: #87a0bf;
		}

		#homeLightingCatalogBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/12-26-2024/hp/Banner-Snowflake-Desktop-Catalog.jpg') center/cover
				no-repeat;
			background-color: #87a0bf;
		}
	}

	&:has(.hpSplashGrid--anniversarySaleBanner) {
		#homeLightingDesignServicesBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/01-27-2025/hp/Desktop-Banner-BG.jpg') center/cover no-repeat;
			background-color: #87a0bf;
		}

		#homeLightingCatalogBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/01-27-2025/hp/Desktop-Banner-BG.jpg') center/cover no-repeat;
			background-color: #87a0bf;
		}
	}

	&:has(.hpSplashGrid--presidentsDaySaleBanner) {
		#homeLightingDesignServicesBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/02-03-2025/hp/Desktop-Banner-BG.jpg') center/cover no-repeat;
			background-color: #85577C;
		}

		#homeLightingCatalogBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/02-03-2025/hp/Desktop-Banner-BG.jpg') center/cover no-repeat;
			background-color: #85577C;
		}
	}

	&:has(.hpSplashGrid--minkaSaleBanner) {
		#homeLightingDesignServicesBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/02-18-2025/hp/BG-Lighting-Decor.jpg') center/cover no-repeat;
			background-color: #35641B;
		}

		#homeLightingCatalogBanner .hpBucketWrapper__adjacentLayout-text {
			background: url('/images/index/ads/02-18-2025/hp/BG-Lighting-Decor.jpg') center/cover no-repeat;
			background-color: #35641B;
		}
	}
}
